@use "../styles/_variables" as var;

canvas {
  position: fixed;
  z-index: -2;
}

.dot-grid {
  background: radial-gradient(gray 1px, transparent 2px);
  background-size: 25px 25px;
  width: 100px;
  height: 75px;
  position: fixed;
  left: 35px;
  bottom: 25px;
  z-index: -1;
}

.plus-grid {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-25 -25 35 35'%3E%3Cpath d='M 0 0 L 5 0 L 5 -1 L 0 -1 L 0 -6 L -1 -6 L -1 -1 L -6 -1 L -6 0 L -1 0 L -1 5 L 0 5 L 0 0 Z' fill='gray'/%3E%3C/svg%3E" );
  background-size: 30px 30px;
  right: 15px;
  width: 90px;
  height: 150px;
  position: fixed;
  z-index: -1;
}
